import { Route } from "./routes";

const EVENT_RELATED_ROUTE_MAP: Record<string, Route> = {
  manual_journal_entry: Route.manualJournals,
  historical_journal_entry: Route.manualJournals,
  bill_received: Route.bills,
  bill_voided: Route.bills,
  payroll: Route.payroll,
};
export const relatedRouteForEventType = (eventType: string) => {
  return EVENT_RELATED_ROUTE_MAP[eventType] || Route.transactions;
};
