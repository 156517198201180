import { useActiveCompany } from "components/companies";
import { AccountChecklistItemFragment } from "./types";
import { useMemo } from "react";
import {
  getNewReconPath,
  getReconPath,
} from "components/dashboard/Accounting/Reconciliation/LedgerReconciliation/shared";
import { Route } from "lib/routes";
import { endOfMonth, isSameMonth, parseCalendarMonth, today } from "@puzzle/utils";
import { CREATE_AUTOMATICALLY_QUERY_KEY } from "../Accounting/Reconciliation/LedgerReconciliation/ViewOrCreateRecon/NewRecon";

export const useAccountChecklistItemRedirects = (
  item: Omit<AccountChecklistItemFragment, "id" | "__typename">
) => {
  const { account, dataAnomalyReview } = item;
  const { timeZone } = useActiveCompany<true>();
  const month = parseCalendarMonth(item.month);
  const isCurrentMonth = useMemo(() => isSameMonth(month, today(timeZone)), [month, timeZone]);
  const endingDate = isCurrentMonth ? today(timeZone).toString() : endOfMonth(month).toString();
  const isReconciled = dataAnomalyReview.context.reconciledThrough;
  const redirectToRecon = useMemo(() => {
    const { ledgerReconciliationSummary } = account?.ledgerAccount || {};
    const { lastReconciled, active } = ledgerReconciliationSummary || {};
    return isReconciled ? lastReconciled : active;
  }, [account.ledgerAccount, isReconciled]);

  const href = useMemo(() => {
    const { id } = account?.ledgerAccount || {};
    if (redirectToRecon?.id) {
      return getReconPath(redirectToRecon?.id);
    } else if (id) {
      return `${getNewReconPath(
        id
      )}?ending-date=${endingDate}&${CREATE_AUTOMATICALLY_QUERY_KEY}=true`;
    } else {
      return Route.ledgerReconciliations;
    }
  }, [account?.ledgerAccount, redirectToRecon, endingDate]);
  return { href, suggestedEndingDate: endingDate };
};
