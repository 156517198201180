import React from "react";
import { styled, Text } from "@puzzle/ui";
import * as Stitches from "@stitches/react";

import { StatusIcon, StatusIconState } from "@puzzle/ui";

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "$1",

  svg: {
    marginBottom: "$1h",
  },
});

const StatusMessage = ({
  title,
  label,
  status,
  css,
}: {
  title: string;
  label: string;
  status: StatusIconState;
  css?: Stitches.CSS;
}) => {
  return (
    <Root css={css}>
      <StatusIcon size={46} status={status} active />

      <Text type="headingS" color="gray400">
        {title}
      </Text>
      <Text type="bodyS" color="gray200">
        {label}
      </Text>
    </Root>
  );
};

StatusMessage.toString = Root.toString;

export default StatusMessage;
