import React from "react";
import { PzReportLabel } from "./styledComponents";

type SegmentHeaderProps = {
  label: string;
};

const SegmentHeader = ({ label }: SegmentHeaderProps) => {
  return <PzReportLabel>{label}</PzReportLabel>;
};

export default SegmentHeader;
