/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useRef, useEffect, ReactElement } from "react";
import { useList } from "react-use";
import url from "url";
import { Row } from "@tanstack/react-table";

import { colors, styled, DataTable, useDataTable, createColumnHelper } from "@puzzle/ui";
import { formatMoney, parseAbsolute } from "@puzzle/utils";
import { Lock, Confirmed, AIContext } from "@puzzle/icons";

import { BalanceByReportColumn, DetailConfirmedState, DynamicReportType } from "graphql/types";
import {
  ReportBreakoutLineFragment,
  useGetLedgerReportBreakoutByPeriodQuery,
} from "../graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";

import { StatusIcon } from "components/transactions/Cells";

import { EnhancedLedgerReportLine } from "../types";
import InteractiveCell from "../Cells/InteractiveCell";
import { UrlObject } from "url";
import { ManualTransactionTooltip } from "components/dashboard/Transactions/AsteriskTooltip";
import { Route } from "lib/routes";
import { relatedRouteForEventType } from "lib/eventToRoute";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import Link from "components/common/Link";
import { getPeriod } from "../reportClassificationUtils";
import { Box, S } from "ve";

const Container = styled("div", {
  overflow: "hidden",
});

const EventTable = styled("div", {
  maxHeight: "300px",
  overflowY: "scroll",
  borderTop: "1px solid #2d2d40",
  paddingTop: "$2",

  opacity: "1",
  transition: "opacity 0.2s",

  table: {
    borderCollapse: "collapse",
  },

  thead: {
    display: "none",
  },

  'tr[role="row"]': {
    border: "none",
  },

  'tr[role="row"]:hover td': {
    backgroundColor: "#303040",
    color: "$gray300",
  },

  td: {
    padding: "2px $1",
    border: "none",
    color: "$gray400",
    fontSize: "13px",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",

    "&:last-child": {
      textOverflow: "initial",
    },
  },

  svg: {
    verticalAlign: "middle",
  },

  variants: {
    loading: {
      true: {
        opacity: 0.5,
      },
      false: {},
    },
  },
});

const Header = styled("div", {
  display: "flex",
  justifyItems: "space-between",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "$gray500",
  fontSize: "13px",
  lineHeight: "20px",
  marginBottom: "$3",

  "& > div": {
    display: "flex",
    alignItems: "center",
  },
});

const SubHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "$1",
  color: "$gray200",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0 $0h 0 $1",
  fontWeight: "$bold",

  "&:hover": {
    backgroundColor: "#303040",
  },

  "& > *:first-child": {
    maxWidth: "300px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& > *:first-child": {
      display: "inline-block",
      color: "$gray400",
      marginRight: "$1",
    },
  },
});

const Title = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Total = styled("div", {
  flexShrink: "0",
  color: "$gray200",
});

const Button = styled("button", {
  background: "none",
  border: "none",
  padding: "$1",
  color: "$gray300",
  cursor: "pointer",
  outline: "none",
  "&:focus, &:hover": {
    color: "$gray50",
  },
});

const USER_CONFIRMED_COLOR = colors.blue300;
const SYSTEM_ASSIGNED_COLOR = "#595861";

// TODO move these out and incorporate new icons
export const StateToIcon: Record<DetailConfirmedState, ReactElement> = {
  [DetailConfirmedState.Automated]: <Confirmed fill={SYSTEM_ASSIGNED_COLOR} />,
  [DetailConfirmedState.UserAssigned]: <Confirmed fill={USER_CONFIRMED_COLOR} />,
  [DetailConfirmedState.UserRuleAssigned]: <Confirmed fill={USER_CONFIRMED_COLOR} />,
  [DetailConfirmedState.Finalized]: <Confirmed />,
  [DetailConfirmedState.Locked]: <Lock />,
  [DetailConfirmedState.AiAssigned]: <AIContext fill={USER_CONFIRMED_COLOR} />,
};

export const StateToHelpText: Record<DetailConfirmedState, string> = {
  [DetailConfirmedState.Automated]: "System Suggested Category",
  [DetailConfirmedState.UserAssigned]: "User Assigned Category",
  [DetailConfirmedState.UserRuleAssigned]: "User Rule Assigned Category",
  [DetailConfirmedState.Finalized]: "Finalized Category",
  [DetailConfirmedState.Locked]: "System Linked Transaction",
  [DetailConfirmedState.AiAssigned]: "AI suggested category based on user context",
};

const StyledDataTable = styled(DataTable, {
  td: {
    borderBottom: "none !important",
  },

  'tr[data-disabled="true"] td': {
    pointerEvents: "initial",
    cursor: "initial",
    "&, *": {
      color: "inherit ",
    },
  },
}) as unknown as typeof DataTable;

const columnHelper = createColumnHelper<ReportBreakoutLineFragment>();
const useColumns = ({
  isRowDisabled,
}: {
  isRowDisabled: (row: Row<ReportBreakoutLineFragment>) => boolean;
}) => {
  const { timeZone } = useActiveCompany<true>();

  const linkText = (row: Row<ReportBreakoutLineFragment>) => {
    const {
      metadata: { externalId, eventType },
    } = row.original;
    if (eventType && eventType === "manual_journal_entry") {
      return "View manual journal entry";
    }
    if (eventType && eventType === "historical_journal_entry") {
      return "View historical journal entry";
    }
    if (eventType && (eventType === "bill_received" || eventType === "bill_voided")) {
      return "View bill";
    }
    if (eventType && eventType === "payroll") {
      return "View payroll entry";
    }

    return "View transaction";
  };

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return useMemo(
    () => [
      columnHelper.accessor((x) => x.metadata.effectiveAt, {
        header: "Date",
        size: 90,
        cell: ({ getValue }) => dateFormatter.format(parseAbsolute(getValue(), timeZone)),
      }),

      columnHelper.accessor("title", {
        size: 180,
        meta: {
          getCellProps: () => ({ overflow: "ellipsis" }),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return <span title={value ?? undefined}>{value}</span>;
        },
      }),

      columnHelper.accessor((x) => x.detail?.confirmedState, {
        header: "Status",
        size: 40,
        meta: {
          align: "right",
          getCellProps: () => ({ css: { padding: 0 } }),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? <StatusIcon state={value} bordered={false} /> : null;
        },
      }),

      columnHelper.accessor("balance", {
        header: "Balance",
        size: 100,
        meta: {
          align: "right",
        },
        cell: ({ getValue, row }) => {
          const { transaction } = row.original;

          return (
            <>
              {formatMoney({ currency: "USD", amount: getValue()! })}
              {transaction && (
                <ManualTransactionTooltip
                  integrationType={transaction.integrationType}
                  css={{ color: "$gray300", right: 0 }}
                />
              )}
            </>
          );
        },
      }),
    ],
    [dateFormatter, isRowDisabled, timeZone]
  );
};

const Breakout = React.forwardRef<
  HTMLDivElement,
  {
    node: EnhancedLedgerReportLine;
    timePeriod: BalanceByReportColumn;
    header?: React.ReactNode;
    reportType: DynamicReportType;
    href?: UrlObject;
  }
>(({ node, timePeriod, header, reportType, href, ...props }, ref) => {
  const { company } = useActiveCompany();
  const companyId = company!.id;
  const [cursorStack, cursorStackActions] = useList<string>();
  const {
    stickyOptions: { view },
  } = useStickyReportContext();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const matchingPeriodFromFilter =
    !timePeriod.dateRange.toInclusive || !timePeriod.dateRange.fromInclusive
      ? undefined
      : {
          end: timePeriod.dateRange.toInclusive,
          start: timePeriod.dateRange.fromInclusive,
          timePeriodKey: timePeriod.columnKey,
        };

  const matchingBalance = node.balanceByColumn.find((b) => b.columnKey === timePeriod.columnKey);

  const { data, loading, previousData, variables } = useGetLedgerReportBreakoutByPeriodQuery({
    variables: {
      input: {
        companyId: companyId!,
        pathToken: timePeriod.pathToken!,
        after: cursorStack[cursorStack.length - 1],
        limit: 10,
        timeZone,
        type: reportType,
        interval: getPeriod(matchingPeriodFromFilter),
      },
    },
  });

  const reportData = data?.ledgerReportBreakoutByPeriod;
  const previousReportData =
    variables?.input.pathToken === timePeriod.pathToken!
      ? previousData?.ledgerReportBreakoutByPeriod
      : undefined;

  const nextCursor = reportData?.nextPageStartingAfter;

  const onNext = useCallback(() => {
    const after = nextCursor;
    if (after) {
      cursorStackActions.push(after);
    }
  }, [cursorStackActions, nextCursor]);
  const onPrev = useCallback(() => {
    if (cursorStack.length > 0) {
      cursorStackActions.removeAt(cursorStack.length - 1);
    }
  }, [cursorStack.length, cursorStackActions]);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current && !loading) {
      scrollRef.current.scrollTop = 0;
    }
  }, [data, loading]);

  const isRowDisabled = useCallback((row: Row<ReportBreakoutLineFragment>) => {
    const {
      metadata: { externalId, eventType },
    } = row.original;
    if (!externalId || !eventType) {
      // currently all linking happens by externalID, disable those without one
      // safe nav check on eventType
      return true;
    }

    //  todo: consider adding more events to the allowlist
    const LINKABLE_EVENT_TYPE_ALLOWLIST = new Set([
      "bank_transaction",
      "credit_card",
      "manual_journal_entry",
      "historical_journal_entry",
      "bill_received",
      "bill_voided",
      "payment_initiated",
      "payroll",
    ]);
    // dont disable events in the allowlist
    return !LINKABLE_EVENT_TYPE_ALLOWLIST.has(eventType);
  }, []);

  const onRowClick = useCallback(
    (row: Row<ReportBreakoutLineFragment>) => {
      const {
        metadata: { externalId, eventType },
      } = row.original;
      // linking is only done on rows with externalID
      if (!externalId) {
        return;
      }

      // event specific navigation overrides
      if (
        eventType &&
        [
          "manual_journal_entry",
          "historical_journal_entry",
          "bill_received",
          "bill_voided",
          "payroll",
        ].includes(eventType)
      ) {
        const path = `${relatedRouteForEventType(eventType)}/${externalId}`;
        window.open(path);
        return;
      }

      // by default go to transactions page
      const transactionPath = `${Route.transactions}/${externalId}`;
      // TODO drawer
      window.open(
        href ? url.format(href).replace(Route.transactions, transactionPath) : transactionPath
      );
    },
    [href]
  );

  const totalLinkRef = useRef<HTMLAnchorElement | null>(null);
  const total = useMemo(() => {
    const content = formatMoney(matchingBalance?.balance ?? { amount: "0", currency: "USD" });

    const isFixedAsset =
      view === "accrual" &&
      node.title === "Fixed Assets" &&
      reportType === DynamicReportType.BalanceSheet &&
      node.ledgerCoaKeys.includes("fixed_assets");

    const isAccrualRevenueLine =
      view === "accrual" &&
      reportType === DynamicReportType.ProfitAndLoss &&
      node.lineType === "revenue_source";

    if (href && !isAccrualRevenueLine && !isFixedAsset) {
      return (
        <Link href={url.format(href)}>
          <InteractiveCell canOpen external as="a" ref={totalLinkRef}>
            {content}
          </InteractiveCell>
        </Link>
      );
    }

    return <InteractiveCell>{content}</InteractiveCell>;
  }, [href, matchingBalance?.balance, node, reportType, view]);

  useEffect(() => {
    if (total) {
      // Anchors can't be autofocused
      totalLinkRef.current?.focus();
    }
  }, [total]);

  const tableData = useMemo(
    () => reportData?.lines || previousReportData?.lines || [],
    [previousReportData?.lines, reportData?.lines]
  );

  const table = useDataTable({
    data: tableData,
    columns: useColumns({ isRowDisabled }),
  });

  return (
    <Container ref={ref} {...props}>
      {header && <Header>{header}</Header>}

      <SubHeader>
        <Title>
          <span>
            {/* Does this always say "Account"? I guess so... */}
            Account
          </span>

          <span title={node.title}>{node.title}</span>
        </Title>

        <Total>{total}</Total>
      </SubHeader>

      <EventTable loading={loading} ref={scrollRef}>
        <StyledDataTable<ReportBreakoutLineFragment>
          density="mini"
          table={table}
          loading={loading}
          onRowClick={onRowClick}
          isRowDisabled={isRowDisabled}
          css={{
            td: {
              borderBottom: "none !important",
            },

            'tr[data-disabled="true"] td': {
              pointerEvents: "initial",
              cursor: "initial",
              "&, *": {
                color: "inherit ",
              },
            },
          }}
        />
      </EventTable>

      {(cursorStack.length || nextCursor) && (
        <Box css={{ marginTop: S["3"] }}>
          <Box
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: "200px",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            <div>{cursorStack.length > 0 && <Button onClick={onPrev}>Prev</Button>}</div>
            <div>{nextCursor && <Button onClick={onNext}>Next</Button>}</div>
          </Box>
        </Box>
      )}
    </Container>
  );
});

export default Breakout;
