export enum TransactionStatus {
  Uncategorized = "Uncategorized",
  Unfinalized = "Unfinalized",
}

export enum MetricView {
  Transactions = "Transactions",
  Reconciliations = "Reconciliations",
}

export enum IncompleteReconStatus {
  InProgress = "InProgress",
  Unreconciled = "Unreconciled",
}
