import { CalendarDateString } from "scalars";

export interface AutomationSetting {
  id: SettingType;
  title: string;
  description: string;
  overviewText: string[];
  enableText: string;
  disableText?: string;
}

export type AutoCategorizeOptions = {
  autoCategorizeEnabled: boolean;
  minAmount: string;
  maxNotifications: string;
  selectedAccounts: (string | null)[];
};
export enum SettingGroup {
  Revenue = "Revenue",
  DataIngestion = "Data Ingestion",
  Categorization = "Categorization",
}

export enum SettingType {
  AccrualAutomation,
  RevenueSubledger,
  PaymentSubledger,
  AutomaticAssignment,
  AutoBalanceAdjustments,
}

export type EffectiveDateTableRow = {
  status: boolean;
  effectiveFrom?: CalendarDateString | null;
  effectiveTo?: CalendarDateString | null;
};

export const SettingMap: Record<SettingGroup, AutomationSetting[]> = {
  [SettingGroup.Revenue]: [
    // hiding this setting for now until we figure out rev rec
    /*{
      id: SettingType.AccrualAutomation,
      title: "Revenue schedule automation",
      description:
        "For active revenue schedules, automatically post deferred revenue and revenue recognition.",
      overviewText: [
        "When Accrual automation is enabled for an effective date range, Puzzle will auto-post revenue deferral and revenue recognition events for all active revenue recognition schedules. Events and any corresponding journal entries to the accrual-basis general ledger will only be posted if their effective date is within the effective date range.",
        "When Accrual automation is disabled, Puzzle will not auto-post revenue deferral and revenue recognition events for any revenue recognition schedules.",
        "Any events that are not posted can be recorded as manual journal entries.",
        "Warning: Once enabled, this setting cannot be disabled.",
      ],
      enableText:
        "any revenue deferral or revenue recognition events associated with active revenue schedules will be automatically posted to the general ledger starting on that date. ",
    },*/
    {
      id: SettingType.RevenueSubledger,
      title: "Revenue accrual subledger",
      description: "Enable daily subledger consolidation for revenue related to accrual events.",
      overviewText: [
        "When the Revenue accrual subledger is enabled for an effective date range, Puzzle will post deferred revenue and revenue recognition events as daily summary journal entries. The subledger will contain all transaction details, which can be edited; updates to the subledger after the initial summary journal entry will post as a daily adjustment journal entry.",
        "When the Revenue accrual subledger is disabled for an effective date range, Puzzle will post each deferred revenue and revenue recognition event as individual journal entries. Any updates to the transaction (e.g. updating the category, deleting the transaction) will also post as individual journal entries.",
      ],
      enableText:
        "any revenue deferral or revenue recognition events associated with active revenue schedules will be consolidated into a daily view and journal entry starting on that date.",
      disableText:
        "any revenue deferral or revenue recognition events associated with active revenue schedules will not be consolidated into a daily view and journal entry starting on that date.",
    },
  ],
  [SettingGroup.DataIngestion]: [
    {
      id: SettingType.PaymentSubledger,
      title: "Payment processor subledger",
      description:
        "Enable daily subledger consolidation for data from payment processors (such as Stripe)",
      overviewText: [
        "When the Payment processor subledger is enabled for an effective date range, Puzzle will post balance activity from the payment processor as daily summary journal entries. The subledger will contain all transaction details, which can be edited; updates to the subledger after the initial summary journal entry will post as a daily adjustment journal entry.",
        "When the Payment processor subledger is disabled for an effective date range, Puzzle will post all balance activity from the payment processor as individual journal entries. Any updates to the transaction (e.g. updating the category, deleting the transaction) will also post as individual journal entries.",
      ],
      enableText:
        "any payment processor transactions in Puzzle will be consolidated into a daily view and journal entry starting on that date.",
      disableText:
        "any payment processor transactions in Puzzle will not be consolidated into a daily view and journal entry starting on that date.",
    },
    {
      id: SettingType.AutoBalanceAdjustments,
      title: "Auto-balance adjustments",
      description:
        "Automatically post a daily balance adjustment transaction to Treasury accounts.",
      overviewText: [
        "When Auto-balance adjustments are enabled for an effective date range, Puzzle will post the difference between the balance (received via API) and the sum of all Puzzle ledger entries as a journal entry to adjust the balance for each investment account.",
        "When Auto-balance adjustments are disabled for an effective date range, Puzzle will not post these balance adjustment entries.",
      ],
      enableText:
        "Puzzle will post adjustment journal entries for differences between balance and transaction details for all investment accounts starting on that date. ",
      disableText:
        "Puzzle will not post adjustment journal entries for differences between balance and transaction details for all investment accounts starting on that date. ",
    },
  ],
  [SettingGroup.Categorization]: [
    {
      id: SettingType.AutomaticAssignment,
      title: "Auto-assignment of uncategorized transactions",
      description:
        "Automatically assign the company owner any uncategorized transactions meeting defined criteria.",
      overviewText: [
        "When Auto-assignment of uncategorized transactions is enabled, Puzzle will automatically assign the company owner transactions meeting the defined criteria.",
        "If transaction notifications are turned on, the assignee will receive these assigned transactions via email and can respond in plain language via Puzzle’s AI Categorizer to categorize these transactions. You can also categorize in the Puzzle app.",
        "When Auto-assignment of uncategorized transactions is disabled, Puzzle will not automatically assign any transactions. Users can assign transactions manually.",
      ],
      enableText:
        "Puzzle will automatically assign any uncategorized transactions to the company owner with the following criteria:",
      disableText:
        "Puzzle will not automatically assign any uncategorized transactions to the company owner. ",
    },
  ],
};
