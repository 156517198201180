import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { ColumnInstance, HeaderGroup, Row, UseTableInstanceProps } from "react-table";
import { usePrevious, usePreviousDistinct } from "react-use";

import { styled, Popover } from "@puzzle/ui";
import { ChevronRight } from "@puzzle/icons";

import { DynamicReportType } from "graphql/types";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { useActiveCompany } from "components/companies";

import { EnhancedLedgerReportLine } from "./types";
import Breakout from "./Breakout";
import { getLinkForNode, getLinkForNodeGL } from "./util";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

const PopoverRoot = styled(PopoverPrimitive.Root);
const PopoverArrow = Popover.Arrow;
const PopoverAnchor = styled(PopoverPrimitive.Anchor, {
  width: "min-width",
});
const PopoverContent = styled(Popover.Content, {
  maxWidth: "500px",
  backgroundColor: "$rhino700",
  padding: "$2",
  color: "$gray400",
  borderRadius: "$1",
  outline: "none",
  boxShadow: "0px 4px 8px $colors$blackA10",

  // Render above drawer
  zIndex: "1201",
});

export type BreakoutCellData = Pick<UseTableInstanceProps<EnhancedLedgerReportLine>, "rowsById"> & {
  row: Row<EnhancedLedgerReportLine>;
  column: ColumnInstance<EnhancedLedgerReportLine> | HeaderGroup<EnhancedLedgerReportLine>;
};

interface BreakoutContextType {
  reportType: DynamicReportType;
  breakoutData?: BreakoutCellData;
  setBreakoutData: React.Dispatch<React.SetStateAction<BreakoutCellData | undefined>>;
  displayedBreakoutData?: BreakoutCellData;
  renderBreakout: (
    node: React.ReactNode,
    cell: Partial<Pick<BreakoutCellData, "row" | "column">>
  ) => React.ReactNode;
}

const BreakoutContext = createContext<BreakoutContextType | undefined>(undefined);

const useAncestorList = (breakoutData?: BreakoutCellData) => {
  return useMemo(() => {
    if (!breakoutData) {
      return [];
    }

    const { row, rowsById } = breakoutData;
    const { id } = row;

    const result: Row<EnhancedLedgerReportLine>[] = [];
    const parts = id.split(".");
    parts.forEach((x, i) => {
      if (i < parts.length) {
        const newId = parts.slice(0, i + 1).join(".");
        const row = rowsById[newId];

        result.push(row);
      }
    });

    return result;
  }, [breakoutData]);
};

const useBreakoutTitle = (ancestors: Row<EnhancedLedgerReportLine>[]) => {
  const title = useMemo(() => ancestors.map((x) => x.original.title).join(" > "), [ancestors]);

  const formattedTitle = useMemo(() => {
    const result: React.ReactNode[] = [];
    ancestors.forEach((row, i) => {
      if (i < ancestors.length - 1) {
        if (i > 0) {
          result.push(<ChevronRight key={row.id} fill="currentColor" />);
        }

        result.push(row.original.title);
      }
    });

    return result;
  }, [ancestors]);

  return { formattedTitle, title };
};

export const BreakoutProvider = ({
  children,
  reportType,
}: React.PropsWithChildren<{ reportType: DynamicReportType }>) => {
  const { stickyOptions } = useStickyReportContext();
  const [open, setOpen] = useState(false);
  const { company } = useActiveCompany<true>();

  const [breakoutData, setBreakoutData] = useState<BreakoutCellData | undefined>();
  const prevbreakoutData = usePreviousDistinct(breakoutData, (prev, next) => !next);
  // breakoutData is reset to undefined while closing; this preserves the state as the animation completes.
  const displayedBreakoutData = open ? breakoutData : prevbreakoutData;

  const hasFilter =
    stickyOptions.filter?.segments?.segmentIds?.length &&
    stickyOptions.filter?.segments?.segmentIds.length > 0;

  const eventBreakoutEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.EnableEventBreakout)
    ? true
    : !hasFilter || stickyOptions.activeTab !== DynamicReportType.ProfitAndLoss;

  useEffect(() => {
    if (breakoutData) {
      setOpen(true);
    }
  }, [breakoutData]);

  const wasOpen = usePrevious(open);
  useEffect(() => {
    if (wasOpen && !open) {
      setBreakoutData(undefined);
    }
  }, [open, wasOpen]);

  const renderBreakout = useCallback(
    (node: React.ReactNode, cell: Partial<Pick<BreakoutCellData, "row" | "column">>) => {
      const active =
        cell &&
        cell.row === displayedBreakoutData?.row &&
        cell.column === displayedBreakoutData?.column;

      if (active) {
        return <PopoverAnchor asChild>{node}</PopoverAnchor>;
      }

      return node;
    },
    [displayedBreakoutData]
  );

  const ancestorList = useAncestorList(displayedBreakoutData);
  const { formattedTitle } = useBreakoutTitle(ancestorList);
  const period = useMemo(
    () =>
      displayedBreakoutData
        ? displayedBreakoutData.row.original.balanceByColumn.find(
            (p) => p.columnKey === displayedBreakoutData.column.id
          )
        : undefined,
    [displayedBreakoutData]
  );

  const value = useMemo(
    () => ({
      reportType,
      breakoutData,
      setBreakoutData,
      displayedBreakoutData,
      renderBreakout,
      ancestorList,
    }),
    [ancestorList, breakoutData, displayedBreakoutData, renderBreakout, reportType]
  );

  const link = useMemo(
    () =>
      displayedBreakoutData
        ? getLinkForNode({
            nodeConfig: displayedBreakoutData,
            view: stickyOptions.view,
            activeTab: stickyOptions.activeTab,
            filter: stickyOptions.filter,
            squashingEnabled: company.features.squashingEnabled,
          })
        : undefined,
    [
      displayedBreakoutData,
      company.features.squashingEnabled,
      stickyOptions.view,
      stickyOptions.activeTab,
      stickyOptions.filter,
    ]
  );

  return (
    <BreakoutContext.Provider value={value}>
      <PopoverRoot open={open} onOpenChange={setOpen}>
        {children}

        {displayedBreakoutData && period && eventBreakoutEnabled && (
          <PopoverContent side="bottom" align="center">
            <Breakout
              node={displayedBreakoutData.row.original}
              timePeriod={period}
              header={formattedTitle}
              reportType={reportType}
              href={link}
            />
            <PopoverArrow />
          </PopoverContent>
        )}
      </PopoverRoot>
    </BreakoutContext.Provider>
  );
};

export const useBreakout = (cell: BreakoutCellData) => {
  const { stickyOptions } = useStickyReportContext();
  const { company } = useActiveCompany<true>();
  const context = useContext(BreakoutContext);

  if (context === undefined) {
    throw new Error("useBreakout must be used within BreakoutProvider");
  }

  const { displayedBreakoutData } = context;

  const renderBreakout = useCallback(
    (node: React.ReactNode, cell: BreakoutCellData) => {
      const active =
        cell &&
        cell.row === displayedBreakoutData?.row &&
        cell.column === displayedBreakoutData?.column;

      if (active) {
        return <PopoverAnchor asChild>{node}</PopoverAnchor>;
      }

      return node;
    },
    [displayedBreakoutData]
  );

  const getLink =
    isPosthogFeatureFlagEnabled(FeatureFlag.FStoFilteredGL) &&
    [DynamicReportType.BalanceSheet, DynamicReportType.ProfitAndLoss].includes(
      stickyOptions.activeTab
    )
      ? getLinkForNodeGL
      : getLinkForNode;

  const link = getLink({
    nodeConfig: { row: cell.row, column: cell.column },
    view: stickyOptions.view,
    activeTab: stickyOptions.activeTab,
    filter: stickyOptions.filter,
    squashingEnabled: company.features.squashingEnabled,
  });

  return useMemo(() => ({ ...context, renderBreakout, link }), [context, link, renderBreakout]);
};
