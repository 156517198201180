import React from "react";
import Icon, { IconProps } from "./Icon";

const Lock = ({ className, rotate, size = 16, viewBox = "0 0 16 16" }: IconProps) => (
  <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} fill="#595861">
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#595861"
    />
    <path
      d="M6.25 8.75V5.75C6.25 5.21957 6.43437 4.71086 6.76256 4.33579C7.09075 3.96071 7.53587 3.75 8 3.75C8.46413 3.75 8.90925 3.96071 9.23744 4.33579C9.56563 4.71086 9.75 5.21957 9.75 5.75V8.75"
      stroke="#211F35"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="4" y="7" width="8" height="5" rx="1" fill="#211F35" />
  </Icon>
);

export default Lock;
