import { styled } from "@puzzle/ui";

export const PzReportLabel = styled("div", {
  // Always match the height of the potential icon
  textStyle: "$bodyXS",
  height: "20px",
  display: "flex",
  alignItems: "center",

  "@media print": {
    fontSize: "$bodyL",
  },
});
