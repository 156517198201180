import React from "react";
import { colors } from "@puzzle/theme";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import { styled, CSSProps } from "../stitches";

const StyledRadialBarChart = styled(RadialBarChart, {
  svg: {
    overflow: "visible",
  },
});

// TODO needs a better way to handle colors and hover states (can't animate it currently)
const MiniRadialBarChart = ({
  data,
  domain,
  size = 20,
  barBackground = colors.mauve400,
  ...props
}: React.ComponentProps<typeof RadialBarChart> &
  CSSProps & {
    data: {
      name: string;
      fill: string;
      value: number;
    }[];
    domain?: [number, number];
    size?: 20 | 24;
    barBackground?: string;
  }) => {
  return (
    <StyledRadialBarChart
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      width={size}
      height={size}
      cx={size / 2}
      cy={size / 2}
      data={data}
      // Offset 0 to start at the top and go clockwise
      startAngle={90}
      endAngle={-270}
      barSize={2}
      // I can't explain how this works...
      // Maybe better to hardcode sizes...
      innerRadius={data.length === 1 ? size * 0.45 : size * 0.25}
      outerRadius={data.length === 1 ? size * 0.8 : size * 0.65}
      {...props}
    >
      {/* This is the only way to customize the domain */}
      {domain && (
        <PolarAngleAxis
          type="number"
          domain={domain}
          angleAxisId={0}
          tick={false}
          tickLine={false}
        />
      )}

      <RadialBar
        radius={size}
        animationDuration={300}
        background={{ fill: barBackground }}
        cornerRadius={40}
        dataKey="value"
        angleAxisId={0}
      />
    </StyledRadialBarChart>
  );
};

export default MiniRadialBarChart;
