import { ReportLineTag, ReportTimePeriod } from "graphql/types";
import { useMemo } from "react";
import { DeltaColumns, DeltaOptions, EnhancedLedgerReportLine, HighlightRule } from "./types";
import { balanceForColumn } from "./util";
import Big from "big.js";

export const isTotalExpensesNode = (node: EnhancedLedgerReportLine) =>
  node.nodeTags?.includes(ReportLineTag.Expenses);
export const isTotalRevenueNode = (node: EnhancedLedgerReportLine) =>
  node.nodeTags?.includes(ReportLineTag.Revenue);
export const isBurnNode = (node: EnhancedLedgerReportLine) =>
  node.nodeTags?.includes(ReportLineTag.Burn);

const useHighlightRules = ({
  deltaOptions,
  lastTimePeriod,
}: {
  deltaOptions: DeltaOptions;
  lastTimePeriod: ReportTimePeriod;
}) => {
  const { atLeastPercent, atLeastDollarAmount } = deltaOptions;

  return useMemo<HighlightRule[]>(() => {
    return [
      {
        description: "How much money did the company generate (or burn) for the selected period?",
        highlight: (node, columnKey) => {
          return isBurnNode(node) && columnKey === lastTimePeriod.timePeriodKey;
        },
      },
      {
        description: "What spending groups made up more than 10% of total spending?",
        highlight: (node, columnKey) => {
          const { value: percentOfExpenses } = balanceForColumn(
            DeltaColumns.PercentOfExpenses,
            node.balanceByColumn
          );
          return (
            columnKey === DeltaColumns.PercentOfExpenses && Big(percentOfExpenses).abs().gt(0.1)
          );
        },
      },
      {
        description:
          "What were the most significant changes? You can select the dollar and percentage thresholds above.",
        highlight: (node, columnKey) => {
          const columnsToHighlight = [DeltaColumns.DollarDiff, DeltaColumns.PercentDiff];
          if (!columnsToHighlight.includes(columnKey as DeltaColumns)) return false;

          const percentDiffForNode = balanceForColumn(
            DeltaColumns.PercentDiff,
            node.balanceByColumn
          );
          const dollarDiffForNode = balanceForColumn(DeltaColumns.DollarDiff, node.balanceByColumn);

          const percentValuesMatch =
            Big(percentDiffForNode.value).abs().gt(atLeastPercent) &&
            Big(percentDiffForNode.value).abs().toNumber() !== Infinity;

          const dollarValuesMatch =
            Big(dollarDiffForNode.value).abs().gt(atLeastDollarAmount) &&
            Big(dollarDiffForNode.value).abs().toNumber() !== Infinity;

          if (
            columnKey === DeltaColumns.DollarDiff &&
            dollarValuesMatch &&
            Big(percentDiffForNode.value).toNumber() === Infinity
          ) {
            return true;
          }

          return dollarValuesMatch && percentValuesMatch;
        },
      },
      {
        description: "What vendors were new compared to the previous period?",
        highlight: (node, columnKey) => {
          if (node.metadata?.counterpartyType !== "vendor") {
            return false;
          }
          const percentDiffForNode = balanceForColumn(
            DeltaColumns.PercentDiff,
            node.balanceByColumn
          );
          const dollarDiffForNode = balanceForColumn(DeltaColumns.DollarDiff, node.balanceByColumn);

          return (
            Big(percentDiffForNode.value).eq(0) &&
            !Big(dollarDiffForNode.value).eq(0) &&
            columnKey === lastTimePeriod.timePeriodKey
          );
        },
      },
    ];
  }, [atLeastDollarAmount, atLeastPercent, lastTimePeriod.timePeriodKey]);
};

export default useHighlightRules;
