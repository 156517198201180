import React, { useState, useMemo } from "react";
import { colors } from "@puzzle/theme";

import MiniRadialBarChart from "./MiniRadialBarChart";

// I wanted these to be impossible to confuse.
// If this ends up being used elsewhere, we can explore other naming conventions.
export type StatusRingColorKey = "finalized" | "categorized";
export const ColorMap: Record<StatusRingColorKey, { active: string; inactive: string }> = {
  finalized: {
    active: colors.purple700,
    inactive: colors.purple900,
  },
  categorized: {
    active: "#4EACF2",
    inactive: "#38709A",
  },
};

export const StatusRing = ({
  activeOnHover = false,
  active: _active = !activeOnHover,
  colors: valueColors,
  values,
  ...props
}: Partial<React.ComponentProps<typeof MiniRadialBarChart>> & {
  values: number[];
  colors: StatusRingColorKey[];
  active?: boolean;
  activeOnHover?: boolean;
  size?: number;
}) => {
  if (values.length !== valueColors.length) {
    throw new Error("Mismatching value and color lengths");
  }

  const [isHovered, setIsHovered] = useState(false);
  const active = (activeOnHover && isHovered) || _active;

  const data = useMemo(
    () =>
      values.map((value, i) => ({
        // TODO Can't animate the color change; recharts renders a new node
        fill: ColorMap[valueColors[i]][active ? "active" : "inactive"],
        name: `${i}`,
        value,
      })),
    [active, valueColors, values]
  );

  return (
    <MiniRadialBarChart
      barBackground={active ? colors.mauve400 : colors.mauve600}
      data={data}
      domain={[0, 100]}
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};
